@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Montserrat";
}

html,
body {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scroll-none {
  scrollbar-width: 0;
}

.container-fluid {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (min-width: 1024px) {
  .container-fluid {
    padding: 0;
  }
}


/* src/styles.css */

@keyframes expand {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 500px;
    opacity: 1;
  }
}

@keyframes collapse {
  from {
    max-height: 500px;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
}

.expand {
  animation: expand 0.3s ease forwards;
}

.collapse {
  animation: collapse 0.3s ease forwards;
}